import { pusherKey, pusherCluster, backendUrl } from '../app-globals';
import Pusher from 'pusher-js';
import Vue from 'vue';
import store from '../store';
import constants from '../constants';

const userData = store.getters.getUser;
const organization = userData.user.organization;

// create pusher instance
const pusher = new Pusher(pusherKey, {
    cluster: pusherCluster,
    wssPort: 443,
    enabledTransports: ['ws'],
    forceTLS: true,
    encrypted: true,
    auth: {
        headers: {
            Authorization: `Bearer ${userData.token}`,
        },
    },
    authEndpoint: `${backendUrl}/api/broadcasting/auth`,
});

Vue.prototype.$pusher = pusher;

// organization channel
var organizationChannel = pusher.subscribe(`${constants.SOCKET_ORGANIZATION_CHANNEL}.${organization.external_id}`);
organizationChannel.bind(`${constants.SOCKET_BOARD_PUBLISH_SUCCESS}`, (e) => {
    store.dispatch('digiboards/addBoardActivity', e);
    store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_BOARD_REGISTRATION_SUCCESS}`, (e) => {
    store.dispatch('digiboards/addDeviceRegistrationSuccessData', e);
});
organizationChannel.bind(`${constants.SOCKET_TEMPLATE_EDITOR_CONTENT_DOWNLOAD_SUCCESS}`, (e) => {
    store.dispatch('contents/fetchSavedContents', {
      params: {
        categoryType: 'saved_content',
      },
    });
    store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_BOARDS_LICENCE_CREATE_SUCCESS}`, (e) => {
    store.dispatch('digiboards/fetchDigiBoards');
    store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_FREE_TRIAL_LICENCE_ENDS_AT}`, (e) => {
    store.dispatch('payments/recordTrialEndsAtNotification', e);
});
organizationChannel.bind(`${constants.SOCKET_FREE_TRIAL_LICENCE_EXPIRED}`, (e) => {
    store.dispatch('userprofile/fetchOrganizationDetails');
});
organizationChannel.bind(`${constants.SOCKET_ORGANIZATION_MODULE_UPDATED}`, (e) => {
    store.dispatch('orgsettings/fetchOrgSettings');
    store.dispatch('userprofile/fetchUserProfile');
});

export default pusher;
